<!--生产发货详细信息-->
<template>
    <div class="delivery-detail">
        <div class="dialog-form-visible-center">
            <div class="form-content">
                <div>
                    <p>发货单编号</p>
                    <p>{{ deliverydetails.pcb_number }}</p>
                </div>
                <div>
                    <p>送货车号</p>
                    <p>{{ deliverydetails.shch }}</p>
                </div>
                <div>
                    <p>司机姓名</p>
                    <p>{{ deliverydetails.sjxm }}</p>
                </div>
                <div>
                    <p>毛重</p>
                    <p>{{ deliverydetails.gross_weight }}</p>
                </div>
                <div>
                    <p>皮重</p>
                    <p>{{ deliverydetails.tare_weight }}</p>
                </div>
                <div>
                    <p>进场时间</p>
                    <p>{{ deliverydetails.into_time }}</p>
                </div>
                <div>
                    <p>出场时间</p>
                    <p>{{ deliverydetails.out_time }}</p>
                </div>
                <div>
                    <p>到达时间</p>
                    <p>{{ deliverydetails.arrival_time }}</p>
                </div>
                <div>
                    <p>开始卸料时间</p>
                    <p>{{ deliverydetails.unload_time }}</p>
                </div>
                <div>
                    <p>完成卸料时间</p>
                    <p>{{ deliverydetails.unload_over_time }}</p>
                </div>
                <div>
                    <p>签收方量</p>
                    <p>{{ deliverydetails.sign_volume }}</p>
                </div>
                <div>
                    <p>卸货方量</p>
                    <p>{{ deliverydetails.unload_volume }}</p>
                </div>
                <div>
                    <p>过磅方量</p>
                    <p>{{ deliverydetails.weigh_volume }}</p>
                </div>
                <div>
                    <p></p>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'delivery-detail',
    data() {
        return {
            pcbNumber: '',
            deliverydetails: {},
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.pcbNumber = this.extr.pcbNumber;
        this.lookMore();
    },
    methods: {
        // 详细信息
        lookMore() {
            this.$axios
                .get(`/interfaceApi/production/productpcb/deliverydetails/${this.pcbNumber}`)
                .then(res => {
                    this.deliverydetails = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.dialog-form-visible-center
    width 100%
    max-height 8rem
    background #FFF
    display flex
    flex-direction column
    align-items center
    .dialog-form-visible-header
        width 100%
        height 0.6rem
        background url(../../../assets/images/popUpForm/banner.png)
        position relative
        h1
            text-align center
            color #fff
            height 0.6rem
            font-size 0.18rem
            line-height 0.6rem
        span
            position absolute
            top 0rem
            right 0rem
            color #fff
            cursor pointer;
            .el-icon-close
                width 0.6rem
                line-height 0.6rem
                text-align center
                font-size 0.2rem
    .table-content
        width 100%
        padding 0.1rem
        flex 1
        .search-wrapper
            display flex
            justify-content space-between
            padding 0.1rem 0
            .el-input-group__append
                background #1577d2
                width 0.6rem
                border none
                padding 0
                cursor pointer
                .search-btn
                    border none
                    background #1577d2
                    width 0.6rem
                    cursor pointer
                    i
                        color #ffffff
        .select_btn
            background #2978ff
            color #fff
            border none
            width 1rem
            height 0.4rem
            border-radius: 0.06rem
            cursor pointer
        .el-table
            border 1px solid #ccc
            .el-table__header-wrapper
                .el-table__header
                    .has-gutter
                        tr
                            background #edf0f5 !important
            .el-radio__label
                display none
    .footer-btn
        height 0.44rem
        display flex
        justify-content center
        margin 0.2rem 0
        .btn
            width 1.84rem
            height 0.44rem
            border 0.02rem solid rgba(21,119,210,1)
            color #1577D2
            background #fff
            // margin 0 auto
            display inline-block
            cursor pointer
            margin 0 0.2rem
        .confirm_btn
            background #1577D2
            color #ffffff
            border none
    .tuiT_btn
        background rgba(45,68,125,1)
        color #fff
        border none
        width 1rem
        height 0.4rem
        border-radius: 0.06rem
        margin 0.1rem 0.2rem 0 0.2rem
        cursor pointer
    .form-content
        width 100%
        padding .2rem
        display flex
        flex-wrap wrap
        div
            width 50%
            display: flex;
            height: .5rem;
            align-items: center;
            border-left: 1px solid #ccc;
            border-bottom 1px solid #ccc
            &:nth-child(1){
                border-top: 1px solid #ccc;
            }
            &:nth-child(2){
                border-top: 1px solid #ccc;
            }
            p
                display: flex;
                align-items: center;
                padding-left: .2rem;
                height 100%
            p:first-child
                width: 2rem;
                text-align: center;
                border-right 1px solid #ccc
        div:nth-child(2n)
            border-right: 1px solid #ccc;


</style>
